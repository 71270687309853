<template>
  <div>
    <Drawer :mask-closable="false"
            :closable="false"
            :mask="false"
            :transfer="false"
            inner
            scrollables
            :width="width"
            :value="visible">
      <div class="main">
        <div class="topbar j-between">
          <div class="flex a-center">
            <div class="topbar-btn m-r-40"
                 @click.stop="onClickClose">
              <Icon custom="i-icon icon-back"
                    size="32"
                    class="m-r-8"></Icon>
              返回
            </div>
            <div class="topbar-btn m-r-40"
                 @click.stop="onClickClear">
              <Icon custom="i-icon icon-uniE904"
                    size="32"
                    class="m-r-8"></Icon>
              清空
            </div>
            <Button type="primary"
                    style="border-radius: 17px;"
                    @click="onClickConfirm">保存</Button>
          </div>
        </div>
        <div class="create">
          <div class="create-right m-r-60">
            <!-- 企业Logo -->
            <div>
              <div class="create-title">
                <span style="color:#D0021B">*</span>
                <span>企业Logo:</span>
              </div>
              <ImgUpload uploadpath="/company/logo/"
                         @uploadSuccess="uploadLogoSuccess">
                <div class="create-upload">
                  <template v-if="!logo">
                    <Icon custom="i-icon icon-upload_pic"
                          size="60"></Icon>
                    <span>上传Logo</span>
                  </template>
                  <template v-else>
                    <div class="create-upload-img">
                      <img :src="logo">
                    </div>
                  </template>
                </div>
              </ImgUpload>
            </div>
          </div>
          <div class="create-left">
            <!-- 企业名称 联系人 -->
            <div class="flex">
              <div class="m-r-65">
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>企业名称:</span>
                </div>
                <Input v-model="entName"
                       placeholder="企业名称"
                       maxlength="20"
                       style="width:280px" />
              </div>
              <div>
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>联系人:</span>
                </div>
                <Input v-model="userName"
                       placeholder="联系人"
                       maxlength="20"
                       style="width:280px" />
              </div>
            </div>
            <!-- 联系电话 传真 -->
            <div class="flex m-t-15">
              <div class="m-r-65">
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>联系电话:</span>
                </div>
                <Input v-model="phone"
                       placeholder="联系电话"
                       maxlength="11"
                       @on-keyup="phone=formatNumber(phone)"
                       style="width:280px" />
              </div>
              <div>
                <div class="create-title">
                  <span>传真:</span>
                </div>
                <Input v-model="fax"
                       placeholder="传真"
                       maxlength="20"
                       style="width:280px" />
              </div>
            </div>
            <!-- Email 网址 -->
            <div class="flex m-t-15">
              <div class="m-r-65">
                <div class="create-title">
                  <span>Email:</span>
                </div>
                <Input v-model="email"
                       placeholder="Email"
                       maxlength="20"
                       style="width:280px" />
              </div>
              <div>
                <div class="create-title">
                  <span>网址:</span>
                </div>
                <Input v-model="webUrl"
                       placeholder="网址"
                       style="width:280px" />
              </div>
            </div>
            <!-- 地址 -->
            <div class="flex m-t-15">
              <div>
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>地址:</span>
                </div>
                <div class="create-location"
                     v-for="(item,index) in locationList"
                     :key="index">
                  <div class="create-location-left">
                    <AddressChooseComponent :width="168"
                                            :key="index"
                                            :addressinfo="item.location"></AddressChooseComponent>
                    <Input v-model="item.address"
                           placeholder="详细地址"
                           style="width:100%;margin-top:15px" />
                  </div>
                  <div class="create-location-right">
                    <Radio v-model="item.isDefault"
                           @on-change="()=>{onChangeDefaultLocation(item)}"
                           class="p-t-5 p-b-5">默认地址</Radio>
                    <div class="text-right m-t-20">
                      <Icon custom="i-icon icon-remove"
                            size="24"
                            color="#E04C53"
                            class="cursor-pointer"
                            @click.stop="locationList.splice(index,1)"
                            v-if="locationList.length>1"></Icon>
                      <Icon custom="i-icon icon-adds"
                            size="24"
                            color="#0652DD"
                            class="cursor-pointer m-l-8"
                            @click.stop="onClickAddLocation"></Icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="h-30"></div>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { getHolderDetail, createHolder, editHolder } from '../../services/holderapi';
import AddressChooseComponent from '../../components/common/AddressChooseComponent';
import ImgUpload from '../../components/common/ImgUpload';
export default {
  components: { ImgUpload, AddressChooseComponent },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    width: Number,
    holderId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      logo: '',
      entName: '',
      userName: '',
      phone: '',
      fax: '',
      email: '',
      webUrl: '',
      locationList: [{
        location: {
          initialization: false,
          province: '',
          provName: '',
          city: '',
          cityName: '',
          area: '',
          areaName: ''
        },
        address: '',
        isDefault: true
      }],
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.$emit('update:holderId', 0)
        this.clearCreateHolder()
      }
    },
    holderId (newVal) {
      if (newVal && this.visible) {
        this.getHolderDetail()
      } else {
        this.$emit('update:holderId', 0)
        this.clearCreateHolder()
      }
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 只能输入数字
    formatNumber (str) {
      return str.replace(/[^\d]/g, '')
    },
    // 只能输入数字和小数点
    formatFloat (str) {
      return str.replace(/[^\d.]/g, '')
    },
    // 修改默认地址
    onChangeDefaultLocation (row) {
      this.locationList.forEach(item => {
        item.isDefault = false
      })
      row.isDefault = true
    },
    // 添加地址
    onClickAddLocation () {
      this.locationList.push({
        location: {
          initialization: false,
          province: '',
          provName: '',
          city: '',
          cityName: '',
          area: '',
          areaName: ''
        },
        address: '',
        isDefault: false
      })
    },
    // 上传企业logo成功
    uploadLogoSuccess (arr) {
      this.logo = arr[0].url
    },
    // 点击保存
    onClickConfirm () {
      if (!this.logo) return this.$Message.info('请上传企业Logo')
      if (!this.entName) return this.$Message.info('请填写企业名称')
      if (!this.userName) return this.$Message.info('请填写联系人')
      if (!this.phone) return this.$Message.info('请填写联系电话')
      for (const item of this.locationList) {
        if (!item.location.province || !item.location.city || !item.location.area || !item.address) return this.$Message.info('请填写地址')
      }
      let params = {
        entLogo: this.logo,
        entName: this.entName,
        linkmanPhone: this.phone,
        linkman: this.userName,
        fax: this.fax,
        email: this.email,
        webUrl: this.webUrl,
        locationList: this.locationList.map(item => {
          return {
            defaultType: item.isDefault ? 1 : 0,
            provCode: item.location.province,
            prov: item.location.provName,
            cityCode: item.location.city,
            city: item.location.cityName,
            distCode: item.location.area,
            dist: item.location.areaName,
            address: item.address
          }
        }),
      }
      if (!this.holderId) {
        createHolder(params).then(res => {
          if (res.code === 0) {
            this.$Message.info('新建成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        editHolder(this.holderId, params).then(res => {
          if (res.code === 0) {
            this.$Message.info('修改成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 点击取消
    onClickClose () {
      this.$emit('change', false)
      this.clearCreateHolder()
    },
    // 点击清空数据
    onClickClear () {
      this.$Modal.confirm({
        title: '清空数据',
        content: '<p>是否清空数据？</p>',
        onOk: () => {
          this.clearCreateHolder()
        }
      })
    },
    // 清空新增商品
    clearCreateHolder () {
      this.logo = ''
      this.entName = ''
      this.phone = ''
      this.userName = ''
      this.fax = ''
      this.email = ''
      this.webUrl = ''
      this.locationList = [{
        location: {
          initialization: false,
          province: '',
          provName: '',
          city: '',
          cityName: '',
          area: '',
          areaName: ''
        },
        address: '',
        isDefault: true
      }]
    },
    // 获取商品详情
    getHolderDetail () {
      getHolderDetail(this.holderId).then(res => {
        if (res.code === 0) {
          let data = res.data
          this.logo = data.entLogo
          this.entName = data.entName
          this.phone = data.linkmanPhone
          this.userName = data.linkman
          this.fax = data.fax
          this.email = data.email
          this.webUrl = data.webUrl
          if (data.locationList.length > 0) {
            this.locationList = data.locationList.map(item => {
              return {
                location: {
                  initialization: true,
                  province: item.provCode.toString(),
                  provName: item.prov.toString(),
                  city: item.cityCode.toString(),
                  cityName: item.city.toString(),
                  area: item.distCode.toString(),
                  areaName: item.dist.toString()
                },
                address: item.address,
                isDefault: item.defaultType === 1
              }
            })
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  background-color: #f9f9ff;
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    .topbar-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      &:hover {
        color: #0652dd;
      }
    }
    ::v-deep {
      .ivu-select-selection,
      .ivu-input {
        background-color: transparent;
        border-color: #9c9ccd;
        border-radius: 18px;
      }
      .ivu-select-selection span {
        padding-left: 12px;
      }
      .ivu-input {
        padding: 4px 12px;
      }
    }
  }
}
.create {
  height: calc(100% - 160px);
  overflow-y: scroll;
  background-color: #fff;
  display: flex;
  margin: 20px;
  padding: 25px;
  .create-title {
    font-size: 14px;
    line-height: 32px;
    color: #0652dd;
  }
  .create-location {
    display: flex;
    &:not(:first-child) {
      margin-top: 15px;
    }
    .create-location-right {
      margin-left: 20px;
    }
  }
  ::v-deep .ivu-table .ivu-table-header th {
    background-color: #e4edfe;
  }
}
.create-upload {
  width: 240px;
  height: 180px;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 14px;
  }
  .create-upload-img {
    width: 100%;
    height: 100%;
    position: relative;
    span {
      position: absolute;
      right: 8px;
      bottom: 8px;
      padding: 0 16px;
      line-height: 28px;
      color: #fff;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 16px;
    }
    .create-upload-mask {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .create-upload-del {
      position: absolute;
      top: -12px;
      right: -12px;
      cursor: pointer;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
::v-deep .ivu-select-dropdown-list {
  max-height: 300px;
}
::v-deep .ivu-drawer {
  top: 64px;
  .ivu-drawer-body {
    padding: 0;
  }
}
</style>