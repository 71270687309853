<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <div class="flex a-center"
           v-show="selectArrSize===0">
        <div class="topbar-btn m-r-25"
             @click.stop="onClickCreateGoods">
          <Icon custom="i-icon icon-add"
                size="32"
                class="m-r-8"></Icon>
          新增
        </div>
        <Input v-model="searchValue"
               placeholder="登记证持有人"
               search
               @on-search="onSearch"
               style="width:280px" />
      </div>
      <div class="flex a-center"
           v-show="selectArrSize>0">
        <div class="topbar-btn"
             @click.stop="batchDeleteHolder">
          <Icon custom="i-icon icon-delete"
                size="32"
                class="m-r-8"></Icon>
          删除
        </div>
      </div>
    </div>
    <div class="list">
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="columns"
               :data="data"
               @on-select="onSelect"
               @on-select-cancel="onSelectCancel"
               @on-select-all="onSelectAll"
               @on-select-all-cancel="onSelectAll">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop="onClickEditGoods(row,1)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">查看</Button>
            <Button @click.stop="onClickEditGoods(row,2)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">编辑</Button>
            <Button @click.stop="onClickDeleteHolder(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">删除</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>
    <HolderCreateDrawer :width="drawerWidth"
                        v-model="createVisible"
                        :holderId.sync="createHolderId"
                        @on-click-confirm="onClickConfirm"></HolderCreateDrawer>
    <HolderDetailDrawer :width="drawerWidth"
                        v-model="detailVisible"
                        :holderId.sync="detailHolderId"></HolderDetailDrawer>
  </div>
</template>

<script>
import { getHolderList, deleteHolder } from '../../../services/holderapi';
import HolderCreateDrawer from '../../../components/product/HolderCreateDrawer';
import HolderDetailDrawer from '../../../components/product/HolderDetailDrawer';

export default {
  components: { HolderCreateDrawer, HolderDetailDrawer },
  data () {
    return {
      searchValue: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 80,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '登记证持有人',
          key: 'entName',
          tooltip: true,
          minWidth: 160
        },
        {
          title: '联系人',
          key: 'linkman',
          tooltip: true,
          width: 160
        },
        {
          title: '联系电话',
          key: 'linkmanPhone',
          tooltip: true,
          width: 160
        },
        {
          title: '地址',
          key: 'location',
          tooltip: true,
          minWidth: 200,
          // render: (h, params) => {
          //   let locationList = params.row.locationList
          //   let text = ''
          //   if (locationList.length > 0) {
          //     let location
          //     locationList.forEach(item => {
          //       if (item.defaultType === 1) {
          //         location = item
          //       }
          //     })
          //     if (!location) location = locationList[0]
          //     text = location.prov + location.city + location.dist + location.address
          //   }
          //   return h('span', text);
          // }
        },
        {
          title: '操作',
          slot: 'operation',
          width: 140,
          align: 'right'
        }
      ],
      data: [],
      selectArr: new Set(),
      selectArrSize: 0,
      drawerWidth: 0,
      /* 新建持证人 */
      createVisible: false,
      createHolderId: 0,
      /* 持证人详情 */
      detailVisible: false,
      detailHolderId: 0,
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 260
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 260
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.getHolderList()
    },
    onSelectAll (selection) {
      if (selection.length === 0) {
        let data = this.$refs.table.data
        data.forEach((item) => {
          if (this.selectArr.has(item.holderEntId)) {
            this.selectArr.delete(item.holderEntId)
          }
        })
      } else {
        selection.forEach(item => {
          this.selectArr.add(item.holderEntId)
        })
      }
      this.selectArrSize = this.selectArr.size
    },
    //  选中某一行
    onSelect (selection, row) {
      this.selectArr.add(row.holderEntId)
      this.selectArrSize = this.selectArr.size
    },
    //  取消某一行
    onSelectCancel (selection, row) {
      this.selectArr.delete(row.holderEntId)
      this.selectArrSize = this.selectArr.size
    },
    setChecked () {
      var objData = this.$refs.table.$refs.tbody.objData;
      for (let index in objData) {
        if (this.selectArr.has(objData[index].holderEntId)) {
          objData[index]._isChecked = true
        }
      }
    },
    onSearch () {
      this.selectArr = new Set()
      this.selectArrSize = 0
      this.pageNum = 1
      this.getHolderList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getHolderList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getHolderList()
    },
    // 获取持证人列表
    getHolderList () {
      let params = {
        goodsType: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.searchValue) params.entName = this.searchValue
      getHolderList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list.map(item => {
          let locationList = item.locationList
          if (locationList.length > 0) {
            let location
            locationList.forEach(items => {
              if (items.defaultType === 1) {
                location = items
              }
            })
            if (!location) location = locationList[0]
            item.location = location.prov + location.city + location.dist + location.address
          }
          return item
        })
        this.$nextTick(() => {
          this.setChecked();
        })
      })
    },
    // 点击删除持证人
    onClickDeleteHolder (row) {
      this.deleteHolder([row.holderEntId])
    },
    // 批量删除持证人
    batchDeleteHolder () {
      this.deleteHolder([...this.selectArr])
    },
    // 删除持证人
    deleteHolder (list) {
      this.$Modal.confirm({
        title: '删除持证人',
        content: '<p>是否删除持证人？</p>',
        onOk: () => {
          let params = {
            goodsType: 1,
            idList: list.join()
          }
          deleteHolder(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功')
              this.selectArr = new Set()
              this.selectArrSize = 0
              this.getHolderList()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 点击新建持证人
    onClickCreateGoods () {
      this.createVisible = true
      this.createHolderId = 0
    },
    // 点击编辑持证人
    onClickEditGoods (row, type) {
      if (type === 1) {
        this.detailVisible = true
        this.detailHolderId = row.holderEntId
      } else {
        this.createVisible = true
        this.createHolderId = row.holderEntId
      }
    },
    // 点击保存
    onClickConfirm () {
      this.createVisible = false
      this.getHolderList()
    }
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  background-color: #f9f9ff;
  .title {
    font-size: 22px;
    line-height: 66px;
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #d3d1e7;
    position: relative;
    .crumbs {
      line-height: 14px;
      position: absolute;
      left: 23px;
      bottom: 16px;
      span {
        font-size: 14px;
        line-height: 1;
        color: #999;
      }
    }
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    .topbar-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      &:hover {
        color: #0652dd;
      }
    }
    ::v-deep {
      .ivu-select-selection,
      .ivu-input {
        background-color: transparent;
        border-color: #9c9ccd;
        border-radius: 18px;
      }
      .ivu-select-selection span {
        padding-left: 12px;
      }
      .ivu-input {
        padding: 4px 12px;
      }
    }
  }
  .list {
    padding: 20px;
    .table {
      padding: 20px;
      background-color: #fff;
    }
    ::v-deep {
      .ivu-table-cell {
        padding: 0 8px;
      }
    }
  }
  ::v-deep .ivu-drawer {
    top: 64px;
    .ivu-drawer-body {
      padding: 0;
    }
  }
  .importView {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 92px;
      height: 98px;
    }
    h2 {
      font-size: 42px;
      line-height: 59px;
      font-weight: 600;
      color: #3d3e58;
      margin-top: 16px;
    }
  }
}
::v-deep .ivu-select-dropdown-list {
  max-height: 300px;
}
</style>