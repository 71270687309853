import {
  del,
  get,
  patch,
  post,
  put
} from './http'

/**
 * 获取持证企业列表
 */
export const getHolderList = (params) => get('/tradeapp/trace/holder/ent/list', params)

/**
 * 获取持证企业详情
 */
export const getHolderDetail = (id) => get('/tradeapp/trace/holder/' + id)

/**
 * 批量删除持证企业
 */
export const deleteHolder = (params) => del('/tradeapp/trace/holder', params)

/**
 * 新建持证企业
 */
export const createHolder = (params) => post('/tradeapp/trace/holder', params)

/**
 * 修改持证企业
 */
export const editHolder = (id, params) => put('/tradeapp/trace/holder/' + id, params)