<template>
  <div>
    <Drawer :mask-closable="false"
            :closable="false"
            :mask="false"
            :transfer="false"
            inner
            scrollables
            :width="width"
            :value="visible">
      <div class="main">
        <div class="topbar j-between">
          <div class="flex a-center">
            <div class="topbar-btn m-r-40"
                 @click.stop="onClickClose">
              <Icon custom="i-icon icon-back"
                    size="32"
                    class="m-r-8"></Icon>
              返回
            </div>
            <div class="topbar-btn"
                 @click.stop="onClickCreateGoods">
              <Icon custom="i-icon icon-add"
                    size="32"
                    class="m-r-8"></Icon>
              新增商品
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="title">
            <span>持证人信息</span>
          </div>
          <div class="flex p-15">
            <div class="detail-right m-r-60">
              <!-- 企业Logo -->
              <div>
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>企业Logo:</span>
                </div>
                <div class="detail-upload">
                  <template v-if="!logo">
                    <Icon custom="i-icon icon-upload_pic"
                          size="60"></Icon>
                    <span>未上传Logo</span>
                  </template>
                  <template v-else>
                    <div class="detail-upload-img">
                      <img :src="logo">
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="detail-left">
              <!-- 企业名称 联系人 -->
              <div class="flex">
                <div class="m-r-65">
                  <div class="detail-title">
                    <span style="color:#D0021B">*</span>
                    <span>企业名称:</span>
                  </div>
                  <div class="detail-value"
                       style="width:280px">{{entName}}</div>
                </div>
                <div class="m-r-65">
                  <div class="detail-title">
                    <span style="color:#D0021B">*</span>
                    <span>联系人:</span>
                  </div>
                  <div class="detail-value"
                       style="width:280px">{{userName}}</div>
                </div>
                <div>
                  <div class="detail-title">
                    <span style="color:#D0021B">*</span>
                    <span>联系电话:</span>
                  </div>
                  <div class="detail-value"
                       style="width:280px">{{phone}}</div>
                </div>
              </div>
              <!-- 联系电话 传真 -->
              <div class="flex m-t-15">
                <div class="m-r-65">
                  <div class="detail-title">
                    <span>传真:</span>
                  </div>
                  <div class="detail-value"
                       style="width:280px">{{fax}}</div>
                </div>
                <div class="m-r-65">
                  <div class="detail-title">
                    <span>Email:</span>
                  </div>
                  <div class="detail-value"
                       style="width:280px">{{email}}</div>
                </div>
                <div>
                  <div class="detail-title">
                    <span>网址:</span>
                  </div>
                  <div class="detail-value"
                       style="width:280px">{{webUrl}}</div>
                </div>
              </div>
              <!-- 地址 -->
              <div class="flex m-t-15">
                <div>
                  <div class="detail-title">
                    <span style="color:#D0021B">*</span>
                    <span>地址:</span>
                  </div>
                  <template v-for="(item,index) in locationList">
                    <div class="detail-value"
                         :key="index"
                         style="width:974px">
                      {{item.location.provName + item.location.cityName + item.location.areaName + item.address}}
                      <Icon custom="i-icon icon-default"
                            size="18"
                            color="#0652DD"
                            class="detail-value-location"
                            v-if="item.isDefault"></Icon>
                    </div>
                  </template>
                </div>
              </div>
              <div class="h-30"></div>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="title">
            <span>商品信息</span>
          </div>
          <div class="table">
            <Table :height="tableHeight"
                   :columns="columns"
                   :data="data">
              <template slot-scope="{ row }"
                        slot="operation">
                <Button @click.stop="onClickEditGoods(row,1)"
                        type="text"
                        style="color: #0652DD;width:40px;text-align: center;padding: 0">查看</Button>
                <Button @click.stop="onClickEditGoods(row,2)"
                        type="text"
                        style="color: #0652DD;width:40px;text-align: center;padding: 0">编辑</Button>
                <Button @click.stop="onClickDeleteGoods(row)"
                        type="text"
                        style="color: #0652DD;width:40px;text-align: center;padding: 0">删除</Button>
              </template>
            </Table>
            <Page class="m-t-10 m-l-10"
                  :total="totalPage"
                  :current="pageNum"
                  :page-size="pageSize"
                  show-total
                  show-sizer
                  show-elevator
                  @on-change="onPageChange"
                  @on-page-size-change="onPageSizeChange" />
          </div>
        </div>
      </div>
    </Drawer>
    <GoodsDrawer :width="width"
                 v-model="goodsVisible"
                 :goodsId="goodsId"
                 :type="operType"
                 @on-click-confirm="onClickConfirm"
                 @on-click-delete="onClickDetailDelete"></GoodsDrawer>
  </div>
</template>

<script>
import { getHolderDetail } from '../../services/holderapi';
import { getGoodsList, deleteGoods } from '../../services/goodsapi';
import GoodsDrawer from './GoodsDrawer';
export default {
  components: { GoodsDrawer },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    width: Number,
    holderId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      logo: '',
      entName: '',
      userName: '',
      phone: '',
      fax: '',
      email: '',
      webUrl: '',
      locationList: [{
        location: {
          initialization: false,
          province: '',
          provName: '',
          city: '',
          cityName: '',
          area: '',
          areaName: ''
        },
        address: '',
        isDefault: false
      }],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      columns: [
        {
          title: '序号',
          width: 80,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '农药类别',
          key: 'pestType',
          tooltip: true,
          width: 160
        },
        {
          title: '登记证号',
          key: 'regSerial',
          tooltip: true,
          width: 160
        },
        {
          title: '商品名称',
          key: 'goodsName',
          tooltip: true,
          width: 160
        },
        {
          title: '有效成分含量',
          key: 'mainIngredient',
          tooltip: true,
          minWidth: 120
        },
        {
          title: '剂型',
          key: 'dosageForm',
          tooltip: true,
          width: 160
        },
        {
          title: '规格',
          key: 'spec',
          tooltip: true,
          minWidth: 120
        },
        {
          title: '持证企业',
          key: 'entName',
          tooltip: true,
          minWidth: 240
        },
        {
          title: '操作',
          slot: 'operation',
          width: 140,
          align: 'right'
        }
      ],
      data: [],

      goodsId: 0,
      goodsVisible: false,
      operType: 0
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.$emit('update:holderId', 0)
      }
    },
    holderId (newVal) {
      if (newVal && this.visible) {
        this.getHolderDetail()
      } else {
        this.$emit('update:holderId', 0)
      }
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 660
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 660
      }
    },
    // 点击取消
    onClickClose () {
      this.$emit('change', false)
    },
    // 获取持证人详情
    getHolderDetail () {
      getHolderDetail(this.holderId).then(res => {
        if (res.code === 0) {
          let data = res.data
          this.logo = data.entLogo
          this.entName = data.entName
          this.phone = data.linkmanPhone
          this.userName = data.linkman
          this.fax = data.fax
          this.email = data.email
          this.webUrl = data.webUrl
          if (data.locationList.length > 0) {
            this.locationList = data.locationList.map(item => {
              return {
                location: {
                  initialization: true,
                  province: item.provCode.toString(),
                  provName: item.prov.toString(),
                  city: item.cityCode.toString(),
                  cityName: item.city.toString(),
                  area: item.distCode.toString(),
                  areaName: item.dist.toString()
                },
                address: item.address,
                isDefault: item.defaultType === 1
              }
            })
          } else {
            this.locationList = [{
              location: {
                initialization: false,
                province: '',
                provName: '',
                city: '',
                cityName: '',
                area: '',
                areaName: ''
              },
              address: '',
              isDefault: false
            }]
          }
          this.getGoodsList()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 页数改变
    onPageChange (event) {
      this.selectArr = []
      this.pageNum = event
      this.getGoodsList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.selectArr = []
      this.pageSize = event
      this.getGoodsList()
    },
    // 获取商品列表
    getGoodsList () {
      let params = {
        goodsType: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        licenseEntName: this.entName
      }
      getGoodsList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list.map(item => {
          item.pestType = item.extra.pestType
          item.regSerial = item.extra.regSerial
          item.mainIngredient = item.extra.mainIngredient
          item.dosageForm = item.extra.dosageForm
          item.entName = item.holderList.length > 0 ? item.holderList[0].entName : ''
          return item
        })
      })
    },
    // 点击删除商品
    onClickDeleteGoods (row) {
      this.onDeleteGoods([row.goodsId])
    },
    // 删除商品
    onDeleteGoods (list) {
      this.$Modal.confirm({
        title: '删除商品',
        content: '<p>是否删除商品？</p>',
        onOk: () => {
          let params = {
            goodsType: 1,
            idList: list.join()
          }
          deleteGoods(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功')
              this.selectArr = []
              this.getGoodsList()
              this.goodsVisible = false
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 点击新建商品
    onClickCreateGoods () {
      this.goodsId = 0
      this.operType = 1
      this.goodsVisible = true
    },
    // 点击编辑商品
    onClickEditGoods (row, type) {
      this.goodsId = row.goodsId
      this.operType = type === 1 ? 3 : 2
      this.goodsVisible = true
    },
    // 点击保存
    onClickConfirm () {
      this.getGoodsList()
    },
    // 点击详情内的删除
    onClickDetailDelete () {
      this.selectArr = []
      this.getGoodsList()
    }
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  background-color: #f9f9ff;
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    .topbar-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      &:hover {
        color: #0652dd;
      }
    }
    ::v-deep {
      .ivu-select-selection,
      .ivu-input {
        background-color: transparent;
        border-color: #9c9ccd;
        border-radius: 18px;
      }
      .ivu-select-selection span {
        padding-left: 12px;
      }
      .ivu-input {
        padding: 4px 12px;
      }
    }
  }
  .title {
    padding: 0 16px;
    border-bottom: 1px solid #d2d0e7;
    display: flex;
    span {
      line-height: 52px;
      color: #0652dd;
      border-bottom: 1px solid #0652dd;
    }
  }
  .list {
    background-color: #fff;
    margin: 20px;
    .table {
      padding: 10px 20px 20px;
    }
    ::v-deep {
      .ivu-table-cell {
        padding: 0 8px;
      }
    }
  }
}
.detail {
  background-color: #fff;
  margin: 20px;
  .detail-title {
    font-size: 14px;
    line-height: 32px;
    color: #0652dd;
  }
  .detail-value {
    min-height: 32px;
    font-size: 14px;
    line-height: 32px;
    padding: 0 10px;
    border-bottom: 1px solid #d4d3e9;
    position: relative;
    .detail-value-location {
      position: absolute;
      right: 0;
      bottom: 6px;
    }
  }
  ::v-deep .ivu-table .ivu-table-header th {
    background-color: #e4edfe;
  }
}
.detail-upload {
  width: 240px;
  height: 180px;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    font-size: 14px;
  }
  .detail-upload-img {
    width: 100%;
    height: 100%;
    position: relative;
    span {
      position: absolute;
      right: 8px;
      bottom: 8px;
      padding: 0 16px;
      line-height: 28px;
      color: #fff;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 16px;
    }
    .detail-upload-mask {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .detail-upload-del {
      position: absolute;
      top: -12px;
      right: -12px;
      cursor: pointer;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
::v-deep .ivu-select-dropdown-list {
  max-height: 300px;
}
::v-deep .ivu-drawer {
  top: 64px;
  .ivu-drawer-body {
    padding: 0;
  }
}
</style>